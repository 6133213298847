@import '../../../styles/bootstrap.scss';

.common-navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.common-navbar__logo {
  min-width: 160px;
}

// align-items-center flex-row justify-content-start
.common-navbar__menu-wrapper {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .navbar .user-section {
    flex: 1 1 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.navbar .dropdown-menu {
  position: absolute;
  font-family: 'SVN-GilroyRegular';
}

.nav-chain {
  .chain-btn {
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-size: 12px !important;
    line-height: 16px !important;
    display: flex;
    align-items: center;
    color: $neutrals-middle-2 !important;
    min-width: 150px;
    font-family: 'SVN-GilroyBold';
    padding: 4px 12px !important;
  }

  .chain-btn > div {
    margin-right: auto;
  }

  .chain-btn.btn-secondary,
  .chain-btn.btn-secondary:hover,
  .chain-btn.btn-secondary:focus,
  .chain-btn.btn-secondary:active,
  .chain-btn.btn-secondary:visited {
    background: #f5f7fb !important;
    border: 1px solid #e3e8f1 !important;
  }

  .chain-btn.btn.dropdown-toggle:after {
    margin-left: 12px !important;
  }

  .network.dropdown-item {
    font-family: 'SVN-GilroyRegular';
    font-weight: 600;
    color: $neutrals-middle-2;
  }

  .drop-chains.dropdown-menu {
    min-width: 245px;
  }
}

.setting-btn {
  min-width: unset !important;
}

.nav-menu {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $bg-dark !important;
}

.lh-16 {
  line-height: 16px;
}

.icon-dashboard {
  height: 24px;
}

.icon-network {
  height: 28px;
  width: 28px;
}

.nav-chain__chain-name {
  padding-left: 8px;
}

.link-active {
  color: #38c1ff !important;
}

@media screen and (max-width: 575px) {
  .navbar {
    // padding: 10px 15px !important;

    .dropdown-toggle {
      .network {
        display: none;
      }
    }
    .common-navbar {
      padding: 0 !important;
    }

    .common-navbar__logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 100px;
    }

    .nav-chain {
      .chain-btn {
        min-width: 30px;
        padding: 0 !important;

        .icon-network {
          height: 30px;
          width: 30px;
        }
      }

      .chain-btn.btn.dropdown-toggle:after {
        display: none;
      }

      .chain-btn.btn-secondary,
      .chain-btn.btn-secondary:hover,
      .chain-btn.btn-secondary:focus,
      .chain-btn.btn-secondary:active,
      .chain-btn.btn-secondary:visited {
        background: transparent !important;
        border: none !important;
      }
    }
  }
  .navbar-expand-md {
    align-items: flex-start;
  }
  .network-title {
    // font-size: 0.85rem;
    height: auto;
  }
  .common-navbar__menu-icon {
    margin-left: -8px;
    height: 40px;
  }
  .common-navbar__menu-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    margin: 0px !important;
    padding-bottom: 8px;
    background: #e2e8ef;

    .nav-link {
      padding-left: 16px !important;
      margin-top: 10px;
    }
  }
  .navbar-nav .navbar-nav li {
    padding: 0 6px;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav {
    align-items: center;
  }
}

.gas-fee-options > span {
  cursor: pointer;
  padding: 4px 8px;
  background-color: #c3c3c5;
  color: #fff;
  border-radius: 8px;
  margin-right: 16px;
}

.gas-fee-options > span.active {
  background-color: #4a4d50;
}

.action-header .modal-title {
  width: 100%;
}
