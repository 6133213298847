@import '../../../../styles/bootstrap.scss';

.address-dropdown {
  .icon-coin {
    width: 40px;
    height: 40px;
  }

  .dropdown-menu {
    width: 220px;
    box-shadow: 0px 0px 15px rgba(5, 32, 73, 0.15);
    border-radius: 8px;
    font-family: 'SVN-GilroyRegular';
  }

  .address-btn {
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 16px !important;
    display: flex;
    align-items: center;
    color: #576e86 !important;
    font-weight: 500 !important;
    font-family: 'SVN-GilroyBold';
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .address-btn.btn-secondary,
  .address-btn.btn-secondary:hover,
  .address-btn.btn-secondary:focus,
  .address-btn.btn-secondary:active,
  .address-btn.btn-secondary:visited {
    background: #f5f7fb !important;
    border: 1px solid #e3e8f1 !important;
  }
  .coin-name {
    font-family: 'SVN-GilroyRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #00344f;
  }
  .coin-amount {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $neutrals-middle-2;
  }
  .icon-profile,
  icon-signout {
    width: 24px;
    height: 24px;
  }
  .title-link {
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: $neutrals-middle-2;
  }
}

@media screen and (max-width: 575px) {
  .address-dropdown {
    .address-btn {
      padding: 8px 16px !important;
    }

    .address-dropdown__menu {
      min-width: 220px !important;
    }
  }
}
