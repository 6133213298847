@import '../../../../styles/bootstrap.scss';

.btn-connect-wallet {
  height: 38px;
  .text-btn {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media screen and (max-width: 575px) {
  .btn-connect-wallet {
    height: 34px;
  }
}

.choose-wallet-modal {
  .action-header.modal-header {
    padding: 10px 16px!important;
  }

  .modal-content .modal-header .modal-title {
    width: 100%;
    .text-title {
      font-family: "SVN-GilroyBold";
      font-weight: bold;
      font-size: 24px;
      height: 11px;
    }

    .btn-close {
      color: $bg-dark!important;
      cursor: pointer;
      font-size: 30px;
    }
  }
  .connect {
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;

    .ic-connect {
      padding-top: 16px;
      img {
        width: 64px;
        height: 64px;
      }
    }
    .title-connect {
      padding-top: 16px;
      padding-bottom: 16px;
      text-transform: capitalize;
    }
    &:hover {
      background: $neutrals-7;
    }
  }
}
