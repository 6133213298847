// Modals
// Design element Dialogs
// --------------------------------------------------
.modal-content {
  border: 0;
  // background: $card-black-background;

  // Modal header
  // Top section of the modal w/ title and dismiss

  .modal-header {
    // border-bottom: none;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DBDBDC;
    & button {
        position: absolute;
        right: 27px;
        top: 24px;
        outline: 0;
        padding: 1rem;
        margin: -1rem -1rem -1rem auto;
    }
    .title{
        color: $gray-100;
        margin-top: 5px;
        margin-bottom: 0;
    }

    .modal-title{
      color: $neutrals-1;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    i.tim-icons {
      font-size: 16px;
    }
  }


  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    line-height: 1.9;

    p{
      color: $gray-100;
    }
  }
  // Footer (for actions)
  .modal-footer {
    border-top: 0;
    -webkit-justify-content: space-between; /* Safari 6.1+ */
    justify-content: space-between;

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;

      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }

  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }
}
.modal-backdrop {
  background: $modal-backdrop-bg;
}

.modal{

    &.modal-default{
        @include modal-colors($white, $black);
    }

    &.modal-primary{
        @include modal-colors($primary, $white);
    }

    &.modal-danger{
        @include modal-colors($danger, $white);
    }

    &.modal-warning{
        @include modal-colors($warning, $white);
    }

    &.modal-success{
        @include modal-colors($success, $white);
    }

    &.modal-info{
        @include modal-colors($info, $white);
    }

    .modal-header .close{
        color: $danger;
        text-shadow: none;

        &:hover,
        &:focus{
            opacity: 1;
        }
    }

    &.modal-black{
      .modal-content{
        background: linear-gradient(to bottom, $black 0%, $black-states 100%);
        color: rgba($white, 0.8);
        .modal-header{
          .modal-title, .title{
            color: rgba($white, 0.9);
          }
        }
        .modal-body{
          p{
            color: rgba($white, 0.8);
          }
        }
      }
      h1, h2, h3, h4, h5, h6, p{
        color: $white;
      }
    }
}

.modal-search{
  .modal-dialog{
    margin: 20px auto;
    max-width: 650px;
    input{
      border: none;
      font-size: 17px;
      font-weight: 100;
    }
    span{
      font-size: 35px;
      color: $search-gray;
    }
  }
  .modal-content{
    .modal-header{
      padding: 24px;
    }
  }

  .modal-header .close{
    color: $dark-background;
    top: 30px !important;
  }

  .modal-footer{
    border-top: 2px solid #f9f9f9;
    margin: 0px 25px 20px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}