// Override default variables before the import
// Import Bootstrap and its default variables
$font-family-base: 'SVN-GilroyRegular' !default;

@import './nucleo/nucleo.scss';
@import './bootstrap.scss';
@import '~video-react/styles/scss/video-react';

select.form-control option {
  color: #888;
}

.bg-navbar {
  background-color: $navbar-color;
}
.bg-dark-gray {
  background-color: $gray-800;
}
.bg-dark {
  background-color: $gray-900;
}

/* Remove background autofill chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input.not-spin::-webkit-outer-spin-button,
input.not-spin::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'].not-spin {
  -moz-appearance: textfield; /* Firefox */
}
hr {
  border-color: $hr-border-color;
}
.status {
  display: inline-block;
  padding: 4px 12px;
  background: $gray-800;
  border-radius: 8px;
}
// .table .status {
//   padding: 4px 0px;
//   background: none;
// }
@each $color, $value in $theme-colors {
  .dot-#{$color} {
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: $value;
    margin-right: 6px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.gray-dropdown {
  padding: 0 !important;
  &.show {
    .dropdown-toggle {
      background: $gray-800;
      border-color: $gray-800;
    }
  }
  .dropdown-toggle {
    padding: 0.25rem 1rem !important;
    background: $gray-800;
    border-radius: $border-radius;
    &:hover,
    &:focus {
      background-image: none !important;
    }
  }
  .dropdown-item {
    font-size: 0.875rem;
    padding: 0.25rem 1rem !important;
  }
}
.page-link {
  padding: 0.4rem 0.8rem;
  margin-left: 2px;
  margin-right: 2px;
  border: none;
  border-radius: $border-radius;
}
.pagination-right {
  .pagination {
    justify-content: flex-end;
  }
}
.btn.btn-ghost {
  padding: 0;
  background: transparent;
  &:hover {
    background-color: transparent !important;
    background-image: none !important;
  }
}
.btn.btn-icon.copy-ghost {
  position: relative;
  top: -2px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background: transparent;
  color: $gray-600;
  &:hover {
    background-color: transparent !important;
    background-image: none !important;
    color: $gray-400;
  }
}
.expand {
  flex: 1 1 0;
}

.network-title {
  height: 56px;
  margin-top: 8px;
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .transfer-compact {
    position: fixed;
    top: 92px;
    right: 15px;
    .item {
      width: 320px;
    }
  }
}

.divider-text {
  display: flex;
  align-items: center;
  margin: 1em -1em;
  &:before,
  &:after {
    content: '';
    flex: 1;
    height: 1px;
    margin: 0 1em;
    background: $gray-800;
  }
}
.alert-danger {
  a {
    color: $danger;
    text-decoration: underline;
  }
}
.break-word {
  word-break: break-all;
}
.cursor-pointer {
  cursor: pointer;
}
.footer-link {
  color: $text-muted;
  margin-bottom: 8px;
  display: block;
}
.disabled {
  cursor: not-allowed !important;
}

.input-max {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.invalid-feedback {
  display: block;
}
.white-underline {
  color: $white;
  &:has(href) {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &:hover {
    color: $gray-300;
  }
}
.number-circle,
.check-circle {
  font-size: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $gray-700;
  line-height: 40px;
  text-align: center;
}
.check-circle {
  background-color: rgba($success, 0.1);
  color: $success;
}
.deposit-action {
  width: calc(100% - 56px);
}
.dropdown-item.selected {
  background: rgba($primary, 0.2) !important;
}
.container-nav {
  background-color: aqua;
  // background: $navbar-color;
}
.top-img {
  border-radius: 2px;
  width: 100%;
}
.nft-type {
  right: 8px;
  top: 8px;
  border-radius: 4px;
}
.desc-price {
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(48, 41, 41, 0.78) 45.31%,
    #000000 100%
  );
  background-blend-mode: multiply;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
}
.list-top-nft {
  padding-top: 2px;
  padding-bottom: 2px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.end-time {
  border: 1px solid #0d1116;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 8px;
  margin: 0 8px;
}
.des-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.text-sub2 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
}

.text-dark {
  color: $dark;
}

.text-tag {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
}

.text-sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
}

.text-gray {
  color: $gray;
}
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.max-line1 {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.hastag {
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0px;
  border: 1px solid $tag-border;
  box-sizing: border-box;
  border-radius: $border-radius;
  margin-right: 0.5em;
  padding: 0.25em 0.5em;
  background: $tag-bg;
  color: $tag-color;
}
.notfound-404 {
  position: relative;
  height: 240px;
}
.notfound-404 h3 {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px;
}
.notfound-404 h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 215px;
  font-weight: 900;
  margin: 0;
  color: $dark;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}
.notfound h2 {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: $black;
  margin-top: 0;
  margin-bottom: 25px;
}
.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}
.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0 0 $white;
}

.text-neutral2 {
  color: $neutrals-2;
}

.lh-16 {
  line-height: 16px;
}

.header {
  padding-top: 100px;
}
.text-black {
  color: $black !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.square-1 {
  position: absolute;
  width: 100%;
  height: 900px;
  z-index: 1;
  left: 0;
  top: 0;

  &::before {
    content: '';
    background-size: 100% 100%;
    width: 400px;
    height: 700px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &::after {
    content: '';
    background-image: url('../assets/images/create-1.svg');
    background-size: 100% 100%;
    position: absolute;
    width: 400px;
    height: 700px;
    top: -80px;
    left: 0;
    z-index: 1;
  }
}

.square-2 {
  position: absolute;
  width: 600px;
  height: 800px;
  z-index: 1;
  right: 0;
  bottom: 0;

  &::before {
    content: '';
    background-size: 100% 100%;
    width: 600px;
    height: 800px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &::after {
    content: '';
    background-image: url('../assets/images/create-2.svg');
    background-size: 100% 100%;
    position: absolute;
    width: 600px;
    height: 800px;
    bottom: -50px;
    right: 0;
    z-index: 1;
  }
}

.main-content {
  position: absolute;
  z-index: 4;
  width: 100vw;
}

// table {
//   tbody {
//     tr {
//       &:hover {
//         background-color: $secondary-opacity25;
//       }
//     }
//   }
// }
.rotate-180 {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.text-active {
  color: $primary-accent;
}
.w-50p {
  width: 50px;
}
.w-150p {
  width: 150px;
}
.w-250p {
  width: 250px;
}
.cursor-default {
  cursor: default !important;
}
.max-line2 {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px; /* fallback */
  height: 33px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.max-line3 {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px; /* fallback */
  height: 45px; /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.overflow-x {
  overflow-x: auto;
}
.h-100vh {
  height: 100vh !important;
}
.height-btn {
  height: 52px;
}
.bg-action {
  background-color: $bg-light !important;
}
.text-neutrals-middle-2 {
  color: $neutrals-middle-2 !important;
}
.text-primary-accent {
  color: $primary-accent !important;
}
.text-neutrals-light-5 {
  color: $neutrals-light-5 !important;
}
.text-neutrals-dark-5 {
  color: $neutrals-dark-5 !important;
}
.text-btn {
  padding-top: 4px;
}
.text-label-1-bold {
  font-family: 'SVN-GilroyBold' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
}
.text-label-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-family: 'SVN-GilroyMedium' !important;
}
.text-label-2-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-family: 'SVN-GilroyBold' !important;
}
.text-label-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: 'SVN-GilroyMedium' !important;
}
.text-heading-1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  font-family: 'SVN-GilroyBold' !important;
}
.text-heading-2-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.25px;
  font-family: 'SVN-GilroyBold' !important;
}
.text-heading-2 {
  font-family: 'SVN-GilroyMedium' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.25px;
}
.text-heading-3-bold {
  font-family: 'SVN-GilroyBold' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}
.text-heading-3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: -0.25px;
  font-family: 'SVN-GilroyMedium' !important;
}
.text-body-1-bold {
  font-family: 'SVN-GilroySemiBold' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.text-body-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'SVN-GilroyMedium' !important;
}
.text-body-2-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: 'SVN-GilroySemiBold' !important;
}
.text-body-2 {
  font-family: 'SVN-GilroyMedium' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.text-caption-bold {
  font-family: 'SVN-GilroySemiBold' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.text-caption {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-family: 'SVN-GilroyMedium' !important;
}
.date-and-time {
  font-family: monospace, monospace !important;
}
.over-flow-scroll {
  overflow-y: overlay;
  scrollbar-width: thin;
}
// .over-flow-scroll::-webkit-scrollbar {
//   width: 10px;
//   background-color: #f5f5f5;
// }

.over-flow-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #ffffff;
}

.over-flow-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

.over-flow-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.mh-200p {
  max-height: 100px;
}

.text-breadcrumb {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.25px;
}

.text-neutrals-4 {
  color: $neutrals-4 !important; 
}

.border-dash {
  border: 1px dashed $neutrals-5;
}

.divider-dash {
  border: 1px dashed #dbdbdc;
}

.mw-100p {
  min-width: 100px;
}

.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0px auto;
  border-radius: 4px;
  min-height: 600px;

  &__content {
    // padding: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .empty-data__icon {
      img {
        height: 150px;
      }
    }

    .empty-data__message {
      margin-top: 0.85rem;
    }
  }
}

@media screen and (max-width: 901px) {
  .empty-data {
    min-height: 300px;
  }
}

.empty-data.no-data {
  min-height: unset !important;
}

.text-neutrals-3 {
  color: $neutrals-3 !important;
}

.ic-connector {
  margin-right: 8px;
  img {
    width: 20px;
    height: 20px;
  }
}
