.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: $stepper-color-progress;
  content: '';
  z-index: 1
}

.steps .step .step-icon-wrap::before {
  left: 0
}

.steps .step .step-icon-wrap::after {
  right: 0
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid $stepper-color-progress;
  background-color: $stepper-bg;
  border-radius: 50%;
  color: $stepper-color-progress;
  font-size: 16px;
  line-height: 41px;
  z-index: 5;
  font-weight: bold;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: $black;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none
}

.steps .step:last-child .step-icon-wrap::after {
  display: none
}

.steps .step.processing .step-icon-wrap::before {
  background-color: $primary;
}

.steps .step.processing .step-icon-wrap::after {
  background-color: $stepper-color-progress;
}

.steps .step.processing .step-icon {
  border-color: $primary;
  background-color: $white;
  color: $primary;
}

.steps .step.processing .step-title {
  color: $primary;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: $primary;
}

.steps .step.completed .step-icon {
  border-color: $primary;
  background-color: $primary;
  color: $white;
}

.steps .step.completed .step-title {
  color: $primary;
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
      display: none
  }
}

.bg-faded, .bg-secondary {
  background-color: #f5f5f5 !important;
}
