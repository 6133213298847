.email-notification {
  &__toggle {
    min-width: 0px;
    padding: 0 !important;
  }

  &__toggle.btn.dropdown-toggle:after {
    display: none;
  }

  .email-notification__toggle.btn-secondary,
  .email-notification__toggle.btn-secondary:hover,
  .email-notification__toggle.btn-secondary:focus,
  .email-notification__toggle.btn-secondary:active,
  .email-notification__toggle.btn-secondary:visited {
    background: transparent !important;
    border: none !important;
  }

  .email-notification__menu {
    min-width: 315px;
    button {
      width: auto;
      padding: 8px 15px 5px;
    }
  }
}

@media screen and (max-width: 575px) {
  .email-notification__menu {
    margin-right: -145px !important;
    button {
      justify-content: center;
    }
  }
}
